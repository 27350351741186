<template>
    <v-container fluid>
        <v-row>
            <v-col><h1>Payments</h1></v-col>
        </v-row>
        <v-tabs centered>
            <v-tab>Unpaid</v-tab>
            <v-tab>Paid</v-tab>

            <v-tab-item>
                <v-simple-table>
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Supplier</th>
                        <th>Amount</th>
                        <th>Description</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="payment in unpaidPayments" v-if="String(payment.status) === '1'" :key="payment.id">
                        <td>{{ payment.purchase.name }}</td>
                        <td>{{ payment.purchase.supplier.company_name }}</td>
                        <td>{{ payment.amount }}</td>
                        <td>{{ payment.description }}</td>
                        <td>{{ formatDate(payment.date) }}</td>
                        <td>{{ getStatusText(String(payment.status)) }}</td>
                        <td>
                            <v-btn v-if="String(payment.status) === '1'" @click="Pay(payment.id)">Pay</v-btn>
                            <v-btn v-else @click="Unpay(payment.id)">Unpay</v-btn>
                        </td>
                    </tr>
                </tbody>
                </v-simple-table>
            </v-tab-item>

            <v-tab-item>
                <v-simple-table>
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Supplier</th>
                        <th>Amount</th>
                        <th>Description</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="payment in paidPayments" v-if="payment.status !== '1'" :key="payment.id">
                        <td>{{ payment.purchase.name }}</td>
                        <td>{{ payment.purchase.supplier.company_name }}</td>
                        <td>{{ payment.amount }}</td>
                        <td>{{ payment.description }}</td>
                        <td>{{ formatDate(payment.date) }}</td>
                        <td>{{ getStatusText(String(payment.status)) }}</td>
                        <td>
                            <v-btn v-if="payment.status === '1'" @click="Pay(payment.id)">Pay</v-btn>
                            <v-btn v-else @click="Unpay(payment.id)">Unpay</v-btn>
                        </td>
                    </tr>
                </tbody>
                </v-simple-table>
            </v-tab-item>
        </v-tabs>
<!--         
        <v-simple-table>
            <thead>
                <tr>
                    <th>Item</th>
                    <th>Supplier</th>
                    <th>Amount</th>
                    <th>Description</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="payment in payments" :key="payment.id">
                    <td>{{ payment.purchase.name }}</td>
                    <td>{{ payment.purchase.supplier.company_name }}</td>
                    <td>{{ payment.amount }}</td>
                    <td>{{ payment.description }}</td>
                    <td>{{ payment.date }}</td>
                    <td>{{ getStatusText(payment.status) }}</td>
                    <td>
                        <v-btn v-if="payment.status === '1'" @click="Pay(payment.id)">Pay</v-btn>
                        <v-btn v-else @click="Unpay(payment.id)">Unpay</v-btn>
                    </td>
                </tr>
            </tbody>
        </v-simple-table> -->
    </v-container>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
export default {
    data(){
        return {
            search: '',
            payments: [],
            payment: {
                purchase: {
                    name: '',
                    supplier: {
                        company_name: ''
                    }
                },
                amount: '', // Example amount value
                description: '', // Example description value
                date: '', // Example date value
                status: '', // Example status value
            }
        }
    },
    mounted(){
        this.getPayments();
    },
    computed: {
        filteredPayments() {
            return this.payments
                .filter(payment => payment.description.toLowerCase().includes(this.search.toLowerCase()))
                .sort((a, b) => new Date(b.date) - new Date(a.date)); // 从新到旧排序
        },
        unpaidPayments() {
        return this.payments
            .filter(payment => String(payment.status) === '1')
            .sort((a, b) => new Date(b.date) - new Date(a.date)); // 从新到旧排序
        },
        paidPayments() {
            return this.payments
                .filter(payment => String(payment.status) !== '1')
                .sort((a, b) => new Date(b.date) - new Date(a.date)); // 从新到旧排序
        }
    },
    methods: {
        formatDate(dateString) {
            const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
            const date = new Date(dateString);
            return date.toLocaleDateString('en-GB', options); // 注意这里使用 'en-GB' 以得到 DD-MM-YYYY 格式
        },
       getPayments() {
        const token = localStorage.getItem('token');
            axios.get('/api/pays', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(response => {
                   this.payments = response.data;
               })
               .catch(error => {
                   console.error('Error fetching payments:', error);
               });
       },
       getStatusText(status) {
           if (status === '1') {
               return 'Unpay';
           } else if (status === '2') {
               return 'Paid';
           } else {
               return 'Unknown Status1';
           }
       },
       Pay(id) {
           axios.put(`/api/pays/${id}`, {status: 2})
               .then(response => {
                   this.getPayments();
                   Swal.fire({
                title: 'Success!',  
                    text: 'payment paid successfully.',
                    icon: 'success',
                    showConfirmButton: false, // 不显示确认按钮
                    timer: 2000 // 1秒后自动关闭
                });
               })
               .catch(error => {
                   console.error('Error paying payment:', error);
               });
       },
       Unpay(id) {
           axios.put(`/api/pays/${id}`, {status: 1})
               .then(response => {
                   this.getPayments();
                   Swal.fire({
                    title: 'Success!',  
                    text: 'payment unpaid successfully.',
                    icon: 'success',
                    showConfirmButton: false, // 不显示确认按钮
                    timer: 2000 // 1秒后自动关闭
                });
               })
               .catch(error => {
                   console.error('Error unpaying payment:', error);
               });
       }
    }
}
</script>

