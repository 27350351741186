<template>
    <v-container>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>Summary for Today</v-card-title>
            <v-card-text>
              <p>Period: {{ formatDate(summary.today.start_date) }} to {{ formatDate(summary.today.end_date) }}</p>
              <p>Total Sales: {{ summary.today.total_sales }}</p>
              <p>Total Cost: {{ summary.today.total_cost }}</p>
              <p>Total Delivery: {{ summary.today.total_delivery }}</p>
              <p>Total Expense: {{ summary.today.total_expense }}</p>
              <p>Profit: {{ summary.today.profit }}</p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card>
            <v-card-title>Summary for Yesterday</v-card-title>
            <v-card-text>
              <p>Period: {{ formatDate(summary.yesterday.start_date) }} to {{ formatDate(summary.yesterday.end_date) }}</p>
              <p>Total Sales: {{ summary.yesterday.total_sales }}</p>
              <p>Total Cost: {{ summary.yesterday.total_cost }}</p>
              <p>Total Delivery: {{ summary.yesterday.total_delivery }}</p>
              <p>Total Expense: {{ summary.yesterday.total_expense }}</p>
              <p>Profit: {{ summary.yesterday.profit }}</p>
            </v-card-text>
          </v-card>
        </v-col>
    </v-row>
    <v-row>
      <v-card>
        <div>
          <input type="date" v-model="startDate">
          <input type="date" v-model="endDate">
          <button @click="fetchDailySummary(startDate, endDate)">Update Summary</button>
          <v-row>
            <v-col v-for="(summary, date) in dailySummary" :key="date" cols="4">
              <v-card>
                <v-card-title>{{ formatDate(date) }}</v-card-title>
                <v-card-text>
                  <p>Total Sales: {{ summary.total_sales }}</p>
                  <p>Total Cost: {{ summary.total_cost }}</p>
                  <p>Total Delivery: {{ summary.total_delivery }}</p>
                  <p>Total Expense: {{ summary.total_expense }}</p>
                  <p>Profit: {{ summary.profit }}</p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-row>
    <v-row>
        <v-col>
          <v-card>
            <v-card-title>Summary for This Week</v-card-title>
            <v-card-text>
              <p>Period: {{ formatDate(summary.week.start_date) }} to {{ formatDate(summary.week.end_date) }}</p>
              <p>Total Sales: {{ summary.week.total_sales }}</p>
              <p>Total Cost: {{ summary.week.total_cost }}</p>
              <p>Total Delivery: {{ summary.week.total_delivery }}</p>
              <p>Total Expense: {{ summary.week.total_expense }}</p>
              <p>Profit: {{ summary.week.profit }}</p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card>
            <v-card-title>Summary for Last Month</v-card-title>
            <v-card-text>
              <p>Period: {{ formatDate(summary.last_month.start_date) }} to {{ formatDate(summary.last_month.end_date) }}</p>
              <p>Total Sales: {{ summary.last_month.total_sales }}</p>
              <p>Total Cost: {{ summary.last_month.total_cost }}</p>
              <p>Total Delivery: {{ summary.last_month.total_delivery }}</p>
              <p>Total Expense: {{ summary.last_month.total_expense }}</p>
              <p>Profit: {{ summary.last_month.profit }}</p>
              <p>Rent : 4000</p>
              <p>Loan : 1117</p>
              <p>Expenses : {{ this.lastMonthTotalExpenses() }}</p>
              <p>Total Expenses : {{ this.lastMonthTotalExpenses() }}</p>
              <p>Balance : {{ this.lastMonthTotalBalance() }}</p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card>
            <v-card-title>Summary for This Month</v-card-title>
            <v-card-text>
              <p>Period: {{ formatDate(summary.month.start_date) }} to {{ formatDate(summary.month.end_date) }}</p>
              <p>Total Sales: {{ summary.month.total_sales }}</p>
              <p>Total Cost: {{ summary.month.total_cost }}</p>
              <p>Total Delivery: {{ summary.month.total_delivery }}</p>
              <p>Total Expense: {{ summary.month.total_expense }}</p>
              <p>Profit: {{ summary.month.profit }}</p>
              <p>Rent : 4000</p>
              <p>Loan : 1117</p>
              <p>Expenses : {{ this.monthTotalExpenses() }}</p>
              <p>Total Expenses : {{ this.monthTotalExpenses() }}</p>
              <p>Balance : {{ this.monthTotalBalance() }}</p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card>
            <v-card-title>Summary for This Year</v-card-title>
            <v-card-text>
              <p>Period: {{ formatDate(summary.year.start_date) }} to {{ formatDate(summary.year.end_date) }}</p>
              <p>Total Sales: {{ summary.year.total_sales }}</p>
              <p>Total Cost: {{ summary.year.total_cost }}</p>
              <p>Total Delivery: {{ summary.year.total_delivery }}</p>
              <p>Total Expense: {{ summary.year.total_expense }}</p>
              <p>Profit: {{ summary.year.profit }}</p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <!-- <v-col>
          <v-card>
            <v-card-title>Summary for Each Month</v-card-title>
            <v-card-text>
              <p>Period: {{ summary.each_month.start_date }} to {{ summary.each_month.end_date }}</p>
              <p>Total Sales: {{ summary.each_month.total_sales }}</p>
              <p>Total Cost: {{ summary.each_month.total_cost }}</p>
              <p>Profit: {{ summary.each_month.profit }}</p>
            </v-card-text>
          </v-card>
        </v-col> -->
        
        <v-col>
          <v-card>
            <v-card-title>Summary for Each Month</v-card-title>
            <v-card-text>
              <p v-for="(data, month) in summary.each_month" :key="month">
                <strong>{{ month }}:</strong>
                <br>
                Period: {{ formatDate(data.start_date) }} to {{ formatDate(data.end_date) }}
                <br>
                Total Sales: {{ data.total_sales }}
                <br>
                Total Cost: {{ data.total_cost }}
                <br>
                Total Delivery: {{ data.total_delivery }}
                <br>
                Total Expense: {{ data.total_expense }}
                <br>
                Profit: {{ data.profit }}
                <br><br>
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-for="event in calendarEvents" :key="event.date">
          <v-card>
            <v-card-title>{{ formatDate(event.date) }}</v-card-title>
            <v-card-text>
              <p>Total Shop Minutes: {{ formatMinutes(event.total_shop_minutes) }}</p>
              <p>Shop Open Close Time: {{ event.shop_open_close_time }}</p>
              <div v-for="user in event.user_data" :key="user.user_id">
                <p>User {{ user.user_id }} Open Minutes: {{ formatMinutes(user.user_open_minutes) }}</p>
                <p>User {{ user.user_id }} Open Close Time: {{ user.user_open_close_time }}</p>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-for="month in monthlyData" :key="month.month">
          <v-card>
            <v-card-title>{{ month.month }}</v-card-title>
            <v-card-text>
              <p>Total Shop Time: {{ formatMinutes(month.total_shop_minutes) }}</p>
              <div v-for="user in month.user_data" :key="user.user_id">
                <p>User {{ user.user_id }} Open Minutes: {{ formatMinutes(user.user_open_minutes) }}</p>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-for="year in yearlyData" :key="year.year">
          <v-card>
            <v-card-title>{{ year.year }}</v-card-title>
            <v-card-text>
              <p>Total Shop Time: {{ formatMinutes(year.total_shop_minutes) }}</p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    data() {
      const now = new Date();
      const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      return {
        calendarEvents: [],
        shopOpenTimes: [],
        monthlyData: [], 
        yearlyData: [],
        summary: {
            today: {},
            yesterday: {},
            week: {},
            month: {},
            year: {},
            each_month: {}
        },
        
        dailySummary: {},
        startDate: startOfMonth.toISOString().slice(0, 10),
        endDate: endOfMonth.toISOString().slice(0, 10)
      };
    },
    computed: {
      // events() {
      //   return this.shopOpenTimes.map(shopOpenTime => ({
      //     start: new Date(shopOpenTime.date),
      //     end: new Date(shopOpenTime.date),
      //     name: shopOpenTime.user.name,
      //     hours: shopOpenTime.hours,
      //   }));
      // },
    },
    methods: {
      formatDate(dateString) {
          const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
          const date = new Date(dateString);
          return date.toLocaleDateString('en-GB', options); // 注意这里使用 'en-GB' 以得到 DD-MM-YYYY 格式
      },
      fetchDailySummary(startDate, endDate) {
        axios.get('/api/accounts/summary/?period=daily', {
          params: {
            period: 'daily',
            start_date: startDate,
            end_date: endDate
          }
        })
        .then(response => {
          this.dailySummary = response.data;
        })
        .catch(error => {
          console.error('Error fetching daily summary:', error);
        });
      },
      fetchCalendarData() {
        axios.get('/api/calculate-total-shop-hours')
          .then(response => {
            this.calendarEvents = response.data;
          })
          .catch(error => {
            console.error(error);
          });
      },
      fetchMonthlyData() {
        axios.get('/api/calculate-monthly-shop-hours')
          .then(response => {
            this.monthlyData = response.data;
          })
          .catch(error => {
            console.error(error);
          });
      },
      fetchYearlyData() {
        axios.get('/api/calculate-yearly-shop-hours')
          .then(response => {
            this.yearlyData = response.data;
          })
          .catch(error => {
            console.error(error);
          });
      },
      formatMinutes(minutes) {
        const hrs = Math.floor(minutes / 60);
        const mins = minutes % 60;
        return `${hrs}hr ${mins}min`;
      },
      monthTotalBalance() {
        const totalExpenses = this.monthTotalExpenses();
        const totalBalance = this.summary.month.profit - totalExpenses;
        return totalBalance;
      },
      lastMonthTotalBalance() {
        const totalExpenses = this.lastMonthTotalExpenses();
        const totalBalance = this.summary.last_month.profit - totalExpenses;
        return totalBalance;
      },
      lastMonthTotalExpenses() {
          const rent = 4000.00;
          const loan = 1117.00;
          const thisMonthExpenses = parseFloat(this.summary.last_month.total_expense); // Convert to a number
          const totalExpenses = rent + loan + thisMonthExpenses;
          return totalExpenses.toFixed(2); // Ensure the result has 2 decimal places
      },
      monthTotalExpenses() {
          const rent = 4000.00;
          const loan = 1117.00;
          const thisMonthExpenses = parseFloat(this.summary.month.total_expense); // Convert to a number
          const totalExpenses = rent + loan + thisMonthExpenses;
          return totalExpenses.toFixed(2); // Ensure the result has 2 decimal places
      },
      fetchSummary(period) {
        return axios.get('/api/accounts/summary/?period='+period);
      }
    },
    mounted() {
      this.fetchCalendarData();
      this.fetchMonthlyData();
      this.fetchYearlyData();

      const periods = ['today', 'yesterday', 'week', 'month', 'year', 'each_month', 'last_month'];
      Promise.all(periods.map(period => this.fetchSummary(period)))
        .then(responses => {
          this.summary = responses.reduce((acc, response, index) => {
            acc[periods[index]] = response.data;
            return acc;
          }, {});
        })
        .catch(error => {
          console.error('Error fetching summaries:', error);
        });
        this.fetchDailySummary(this.startDate, this.endDate);
    }
  }
</script>

