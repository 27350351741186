<template>
    <v-container fluid>
      <v-row>
        <v-col><h1>Inventory</h1></v-col>
        <v-col style="text-align: right;"><v-btn v-if="currentStep === 4" color="primary" @click="showFormMethod">Use Inventory</v-btn></v-col>
        <v-col style="text-align: right;"><v-btn v-if="currentStep === 4" color="primary" @click="showReturnFormMethod">Return Inventory</v-btn></v-col>
        <v-col style="text-align: right;"><v-btn v-if="currentStep === 4" color="primary" @click="unbindFormMethod">Unbind</v-btn></v-col>
      </v-row>
      <!-- Step 1 -->
      <transition name="fade" mode="out-in">
      <v-card v-if="currentStep === 1">
            <v-btn @click="currentStep = 4">Back</v-btn>
            <v-card-title>Step 1: Basic Information</v-card-title>
            
            <v-card-text>
              <v-simple-table v-if="!showForm">
                  <template v-slot:default>
                      <thead>
                          <tr>
                              <th class="text-left">Inv No.</th>
                              <th class="text-left">Inv Date</th>
                              <th class="text-left">Description</th>
                              <th class="text-left">Total Price</th>
                              <th class="text-left">Action</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr v-for="invoice in sortedInvoices" :key="invoice.id">
                            <td>{{ invoice.invoice_number }} <span v-if="invoice.inventories_count > 0">({{ invoice.inventories_count }} item)</span></td>
                              <td>
                                  <div>{{ formatDate(invoice.invoice_date) }}</div>
                                  <div>{{ formatTime(invoice.invoice_date) }}</div>
                              </td>
                              <!-- <td>{{ invoice.phone }}</td> -->
                              <td>
                                  <ul>
                                      <li v-for="(description, index) in invoice.item_description" :key="index" style="width: 300px;" class="invoice-item">
                                          {{ description }} - {{ invoice.item_price[index] }}
                                      </li>
                                  </ul>
                              </td>
                              <td>{{ invoice.total }}</td>
                              <td>
                                <v-btn small color="primary" @click="selectInvoice(invoice.id)">Select</v-btn>
                                  <!-- <v-btn small color="default" @click="reprint(invoice)">Reprint</v-btn>
                                  <v-btn small color="primary" @click="editItem(invoice)">Edit</v-btn> -->
                                  <!-- <v-btn small color="error" @click="deleteItem(invoice)">Delete</v-btn> -->
                                  <!-- <v-btn small color="success" @click="markAsCompleted(invoice)">Completed</v-btn> -->
                              </td>
                          </tr>
                      </tbody>
                  </template>
            </v-simple-table>
            </v-card-text>
            <v-card-actions>
                <!-- <v-btn @click="nextStep">Next</v-btn> -->
            </v-card-actions>
        </v-card>

        <!-- Step 2 -->
        <v-card v-if="currentStep === 2">
            <v-card-title>Step 2: Details</v-card-title>
            <v-card-text>
              <v-row>
                <!-- Left Column for Invoice Details -->
                <v-col cols="6">
                    <h3>Invoice Details</h3>
                    <div v-if="selectedInvoiceId">
                        <!-- Assuming fetchInvoiceDetails fetches and stores details in invoiceDetails -->
                        <p><strong>Invoice Number:</strong> {{ invoiceDetails.invoice_number }}</p>
                        <p><strong>Date:</strong> {{ formatDate(invoiceDetails.invoice_date) }}</p>
                        <li v-for="(description, index) in invoiceDetails.item_description" :key="index" style="width: 300px;" class="invoice-item">
                            {{ description }} - {{ invoiceDetails.item_price[index] }}
                        </li>
                        <p><strong>Total:</strong> {{ invoiceDetails.total }}</p>
                    </div>
                </v-col>
                <v-col cols="6">
                  <h3>Select Inventory</h3>
                  <!-- Search Field -->
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search Inventory"
                    single-line
                    hide-details
                  ></v-text-field>
                  <!-- Inventory List with Scroll -->
                  <v-list style="max-height: 300px; overflow-y: auto;">
                    <v-list-item v-for="item in filteredInventories" :key="item.id">
                      <v-list-item-content>
                        <div>Name: {{ item.purchase ? item.purchase.name : 'No name' }}</div>
                        <div>Date: {{ item.purchase ? formatDate(item.purchase.date) : 'No date' }}</div>
                        <div>Price: {{ item.purchase ? item.purchase.unit_price : 'No price' }}</div>
                        <div>Description: {{ item.purchase ? item.purchase.description : 'No description' }}</div>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-checkbox
                          v-model="selectedInventoryIds"
                          :value="item.id"
                        ></v-checkbox>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="previousStep">Previous</v-btn>
                <v-btn @click="nextStep">Next</v-btn>
            </v-card-actions>
        </v-card>

        <!-- Step 3 -->
        <v-card v-if="currentStep === 3">
          <v-card-title>Step 3: Confirmation</v-card-title>
          <v-card-text>
              <v-row>
                  <v-col cols="6">
                      <h3>Invoice Details</h3>
                      <div v-if="selectedInvoiceId">
                          <p><strong>Invoice Number:</strong> {{ invoiceDetails.invoice_number }}</p>
                          <p><strong>Date:</strong> {{ formatDate(invoiceDetails.invoice_date) }}</p>
                          <p><strong>Total:</strong> {{ invoiceDetails.total }}</p>
                          <ul>
                              <li v-for="(description, index) in invoiceDetails.item_description" :key="index">
                                  {{ description }} - {{ invoiceDetails.item_price[index] }}
                              </li>
                          </ul>
                      </div>
                  </v-col>
                  <v-col cols="6">
                      <h3>Selected Inventory</h3>
                      <v-list>
                          <v-list-item
                              v-for="item in selectedInventories" :key="item.id">
                              <v-list-item-content>
                                  <div>Name: {{ item.purchase ? item.purchase.name : 'No name' }}</div>
                                  <div>Date: {{ item.purchase ? formatDate(item.purchase.date) : 'No date' }}</div>
                                  <div>Price: {{ item.purchase ? item.purchase.unit_price : 'No price' }}</div>
                                  <div>Description: {{ item.purchase ? item.purchase.description : 'No description' }}</div>
                              </v-list-item-content>
                          </v-list-item>
                      </v-list>
                  </v-col>
              </v-row>
          </v-card-text>
          <v-card-actions>
              <v-btn @click="previousStep">Previous</v-btn>
              <v-btn @click="submitForm">Submit</v-btn>
          </v-card-actions>
      </v-card>
      <v-card v-if="currentStep === 5">
        <v-card-title>Return Inventory</v-card-title>
        <v-btn @click="currentStep = 4">Back</v-btn>
        <v-simple-table>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Price</th>
                        <th>Date</th>
                        <th>Type</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in inventories" :key="item.id">
                        <td>{{ item.purchase.name }}</td>
                        <td>{{ item.purchase.unit_price }}</td>
                        <td>{{ formatDate(item.purchase.date) }}</td>
                        <td>{{ item.purchase.type === 1 ? 'Part' : 'Accessories' }}</td>
                        <td>
                            <v-checkbox v-model="selectedReturnIds" :value="item.id"></v-checkbox>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
        <v-text-field
            v-model="return_date"
            label="Select Date"
            type="date"
            outlined
            dense
        ></v-text-field>
        <v-btn @click="returnInventoryToSupplier">Submit</v-btn>
    </v-card>
    </transition>
      <v-overlay :value="isLoading">
        <v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
      </v-overlay>
      <v-tabs v-model="tab" background-color="transparent" v-if="currentStep === 4">
      <v-tab>Parts</v-tab>
      <v-tab>Accessories</v-tab>

      <!-- Tab Item for Parts -->
      <v-tab-item >
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>No</th>
                <th>Name</th>
                <th>Price</th>
                <th>Date</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in parts" :key="item.id">
                <td>{{ index + 1 }}</td>
                <td>{{ item.purchase.name }}</td>
                <td>{{ item.purchase.unit_price }}</td>
                <td>{{ formatDate(item.purchase.date) }}</td>
                <td>Part</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-tab-item>

      <!-- Tab Item for Accessories -->
      <v-tab-item>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>No</th>
                <th>Name</th>
                <th>Price</th>
                <th>Date</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in accessories" :key="item.id">
                <td>{{ index + 1 }}</td>
                <td>{{ item.purchase.name }}</td>
                <td>{{ item.purchase.unit_price }}</td>
                <td>{{ formatDate(item.purchase.date) }}</td>
                <td>Accessories</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-tab-item>
      </v-tabs>
      <v-card v-if="currentStep === 6">
        <v-btn @click="currentStep = 4">Back</v-btn>
            <v-card-title>Step 1: Basic Information</v-card-title>
            
            <v-card-text>
              <v-simple-table v-if="!showForm">
                  <template v-slot:default>
                      <thead>
                          <tr>
                              <th class="text-left">Inv No.</th>
                              <th class="text-left">Inv Date</th>
                              <th class="text-left">Description</th>
                              <th class="text-left">Total Price</th>
                              <th class="text-left">Action</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr v-for="invoice in sortedInvoices" :key="invoice.id">
                            <td>{{ invoice.invoice_number }} <span v-if="invoice.inventories_count > 0">({{ invoice.inventories_count }} item)</span></td>
                              <td>
                                  <div>{{ formatDate(invoice.invoice_date) }}</div>
                                  <div>{{ formatTime(invoice.invoice_date) }}</div>
                              </td>
                              <!-- <td>{{ invoice.phone }}</td> -->
                              <td>
                                  <ul>
                                      <li v-for="(description, index) in invoice.item_description" :key="index" style="width: 300px;" class="invoice-item">
                                          {{ description }} - {{ invoice.item_price[index] }}
                                      </li>
                                  </ul>
                              </td>
                              <td>{{ invoice.total }}</td>
                              <td>
                                <v-btn small color="primary" @click="selectUnbindInvoice(invoice.id)" v-if="invoice.inventories_count > 0">Select</v-btn>
                              </td>
                          </tr>
                      </tbody>
                  </template>
            </v-simple-table>
            </v-card-text>
            <v-card-actions>
                <!-- <v-btn @click="nextStep">Next</v-btn> -->
            </v-card-actions>
      </v-card>
      <v-card v-if="currentStep === 7">
        <v-btn @click="currentStep = 4">Back</v-btn>
        <v-card-title>Unbind Inventory</v-card-title>
        <v-card-text>
          <v-row>
            <!-- 左边列显示选中的发票详情 -->
            <v-col cols="6">
              <h3>Invoice Details</h3>
              <div v-if="selectedInvoiceId">
                <p><strong>Invoice Number:</strong> {{ invoiceDetails.invoice_number }}</p>
                <p><strong>Date:</strong> {{ formatDate(invoiceDetails.invoice_date) }}</p>
                <p><strong>Total:</strong> {{ invoiceDetails.total }}</p>
                <ul>
                  <li v-for="(description, index) in invoiceDetails.item_description" :key="index">
                    {{ description }} - {{ invoiceDetails.item_price[index] }}
                  </li>
                </ul>
              </div>
            </v-col>
            <v-col cols="6">
              <div v-for="(inventory, index) in invoiceDetails.inventories" :key="inventory.id">
                <h4>Inventory {{ index + 1 }} Details</h4>
                <p>Name: {{ inventory.purchase ? inventory.purchase.name : 'No name' }}</p>
                <p>Date: {{ inventory.purchase ? formatDate(inventory.purchase.date) : 'No date' }}</p>
                <p>Price: {{ inventory.purchase ? inventory.purchase.unit_price : 'No price' }}</p>
                <p>Description: {{ inventory.purchase ? inventory.purchase.description : 'No description' }}</p>
                <v-checkbox v-model="selectedInventoryIds" :value="inventory.id"></v-checkbox>
              </div>
              <v-btn color="error" @click="unbindSelectedInventories">Unbind Selected</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- <v-data-table v-if="currentStep === 4"
          :headers="headers"
          :items="itemsWithItemTypeText"
          class="elevation-1"
        >
          <template v-slot:item.index="{ index }">
              <span>{{ index + 1 }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn small color="primary" @click="editItem(item)">Edit</v-btn>
            <v-btn small color="error" @click="deleteItem(item)">Delete</v-btn>
          </template>
        </v-data-table> -->
  
      <!-- <v-card v-if="showForm && !isEditMode">
        <v-card-title class="headline">Use Inventory</v-card-title>
        <v-select
              :items="invoices"
              item-value="id"
              item-text="name"
              v-model="selectedInvoiceId"
              label="Select an Invoice"
              @change="fetchInvoiceDetails"
          ></v-select>

          <div v-if="selectedInvoiceId">
              <h3>Invoice Details</h3>
          </div>

          <v-list>
              <v-list-item
                  v-for="item in inventories"
                  :key="item.id"
              >
                  <v-list-item-content>{{ item.name }}</v-list-item-content>
                  <v-list-item-action>
                      <v-checkbox
                          v-model="selectedInventoryIds"
                          :value="item.id"
                      ></v-checkbox>
                  </v-list-item-action>
              </v-list-item>
          </v-list>

          <v-btn @click="assignInventoryToInvoice">Assign Inventory to Invoice</v-btn>
      </v-card> -->
  
      <!-- <v-card v-if="showForm && isEditMode">
        <v-card-title class="headline">Edit Inventory</v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-row>
                <v-text-field v-model="inventory.name" label="Item Name" outlined dense></v-text-field>
            </v-row>
            <v-row>
                <v-text-field v-model="inventory.price" label="Item Price" outlined dense></v-text-field>
            </v-row>
            <v-row>
                <v-text-field v-model="inventory.type" label="Item Type" outlined dense></v-text-field>
                <v-select v-model="inventory.type" label="Item Type" outlined dense :items="typeitems" item-text="typetext" item-value="typevalue"></v-select>
            </v-row>
          </v-form>
        </v-card-text>
        <v-btn color="primary" @click="submitEditInventory">Edit Inventory</v-btn>
        <v-btn color="primary" @click="closeForm">Cansel</v-btn>
      </v-card> -->
    </v-container>
  </template>
    
  <script>
  import Swal from 'sweetalert2'; 
  import { countBy } from 'lodash';
  export default {
    data() {
      return {
        tab: null,
        search: '',
        headers: [
            { text: 'No', value: 'index', sortable: false },
            { text: 'Name', value: 'name' },
            // { text: 'Quantity', value: 'quantity' },
            { text: 'Price', value: 'purchase.unit_price' },
            { text: 'Type', value: 'type' },
            // { text: 'Actions', value: 'actions', sortable: false },
        ],
        items: [
     
        ], // Your inventory items data
        // typeitems: [
        //     { typetext: 'Part', typevalue: 1 },
        //     { typetext: 'Accessories', typevalue: 2 },
        // ],
        isEditMode: false, // 默认为添加模式
        showForm: false, // 控制表单显示的变量
        inventories: [],
        inventory: {
          name: '',
          price: '',
          quantity: '',
          type: '',
        },
        typeitems: [
        { typetext: 'Part', typevalue: 1 },
        { typetext: 'Accessories', typevalue: 2 },
        ],
        invoices: [], // 所有发票
        selectedInvoiceId: null, // 选中的发票ID
        selectedInventoryIds: [], // 被选中的库存项ID数组
        selectedReturnIds: [], // 被选中的退货项ID数组
        invoiceDetails: {}, // 存储选中的发票详情
        inventoryItems: [], // 可选的库存项列表
        isLoading: true,
        invoices: [],
        currentStep: 4,
        totalSteps: 3,
      };
    },
    created() {
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    },
    mounted() {
      this.fetchInventoriesData();
      this.fetchInvoices();
      console.log('Inventories:', this.inventories);
      console.log('Selected Inventory IDs:', this.selectedInventoryIds);
    },
    computed: {
      filteredInventories() {
        const searchLower = this.search.toLowerCase();
        return this.inventories.filter(item => {
          return item.purchase && (
            item.purchase.name.toLowerCase().includes(searchLower) ||
            item.purchase.description.toLowerCase().includes(searchLower) ||
            item.purchase.date.includes(searchLower) ||
            item.purchase.unit_price.toString().includes(searchLower)
          );
        });
      },
      parts() {
        return this.inventories.filter(item => String(item.purchase.type) === '1');
      },
      accessories() {
        return this.inventories.filter(item => String(item.purchase.type) === '2');
      },
      sortedInvoices() {
        return this.invoices.slice().sort((a, b) => {
          // 将日期字符串转换为日期对象进行比较
          return new Date(b.invoice_date) - new Date(a.invoice_date);
        });
      },
      selectedInventories() {
          const filtered = this.inventories.filter(item => this.selectedInventoryIds.includes(item.id));
          console.log('Filtered Inventories:', filtered);
          return filtered;
      },
      sortedInventory() {
        const filteredInventories = this.inventories.filter(inventory => Number(inventory.status) === '1');
        console.log(filteredInventories); // 查看过滤后的结果
  
        const sorted = filteredInventories.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
        console.log(sorted); // 查看排序后的结果
        return sorted;
      },
      itemsWithItemTypeText() {
        return this.inventories.map(inventory => {
          // 如果存在 purchase 数据，则使用 purchase 数据，否则使用 inventory 数据
          const displayData = inventory.purchase_id && inventory.purchase ? inventory.purchase : inventory;

          return {
            ...displayData,
            type: this.getTypeText(displayData.type), // 假设你有一个方法来转换 type 的值为可读文本
          };
        });
      },
    },
    methods: {
      unbindFormMethod() {
        this.currentStep = 6;
      },
      unbindSelectedInventories() {
        // 确保使用 selectedInventoryIds 数组，其中应该包含所有选中的库存项的 ID
        this.selectedInventoryIds.forEach(inventoryId => {
          axios.post(`/api/invoices/${this.selectedInvoiceId}/unbind/${inventoryId}`)
            .then(response => {
              console.log('Unbound inventory:', inventoryId);
              // 过滤掉已经解绑的库存 ID
              this.selectedInventoryIds = this.selectedInventoryIds.filter(id => id !== inventoryId);
              this.fetchInventoriesData(); // 重新获取库存数据
              this.selectedInvoiceId = null; // 重置选中的发票 ID
              this.currentStep = 4; // 可能需要调整到适当的视图或步骤
              this.fetchInvoices(); // 重新获取发票数据
              Swal.fire({
                title: 'Success!',
                text: 'Inventory has been unbound successfully.',
                icon: 'success',
                timer: 2000
              });
            })
            .catch(error => {
              console.error('Error unbinding inventory:', error);
              Swal.fire({
                title: 'Error!',
                text: 'Failed to unbind inventory.',
                icon: 'error',
                timer: 2000
              });
            });
        });
      },
      returnInventoryToSupplier() {
        const returnData = {
            inventory_ids: this.selectedReturnIds, // 选定的库存项ID数组
            return_date: this.return_date // 当前日期
        };

        axios.post('/api/return-items', returnData)
        .then(response => {
            console.log('Items returned successfully:', response.data);
            this.fetchInventoriesData();
            this.selectedReturnIds = [];
            this.return_date = '';
            this.currentStep = 4;
            Swal.fire({
                title: 'Success!',
                text: 'Items returned successfully.',
                icon: 'success',
                showConfirmButton: false, // 不显示确认按钮
                timer: 2000 // 1秒后自动关闭
            });
        })
        .catch(error => {
            console.error('Error returning items:', error.response.data);
        });
      },
      returnInventory(inventoryId) {
          this.currentStep = 5;
          this.selectedInventoryIds = [inventoryId];
      },
      showReturnFormMethod() {
          this.currentStep = 5;
      },
      fetchInvoiceDetails(invoiceId) {
          // 从后端获取发票详情
          axios.get(`/api/invoices/${invoiceId}`).then(response => {
              this.invoiceDetails = response.data;
          });
      },
      // selectInvoice(selectedId) {
      //     this.selectedInvoiceId = selectedId;
      //     this.nextStep();  // 自动跳转到下一步
      // },
      selectUnbindInvoice(invoiceId) {
          this.selectedInvoiceId = invoiceId;
          this.fetchInvoiceDetails(invoiceId); // 获取发票详情
          this.currentStep = 7; // 跳转到下一步
      },
      selectInvoice(invoiceId) {
          this.selectedInvoiceId = invoiceId;
          this.fetchInvoiceDetails(invoiceId); // 获取发票详情
          this.currentStep = 2; // 跳转到下一步
      },
      // nextStep() {
      //     // 增加逻辑来处理步骤的变化，例如增加 currentStep
      //     if (this.currentStep < this.totalSteps) {
      //         this.currentStep++;
      //     }
      // },
      nextStep() {
        if (this.currentStep === 2 && !this.validateStepTwoData()) {
          alert('Please fill in all required fields.');
          return;
        }
        this.currentStep = 3;
      },
      validateStepTwoData() {
        return this.selectedInventoryIds.length > 0;
      },
      previousStep() {
          if (this.currentStep > 1) {
              this.currentStep--;
          }
      },
      submitForm() {
          // 处理表单提交逻辑
          this.assignSelectedInventoriesToInvoice();
          console.log("Form submitted!");
      },
      fetchInvoices() {
          axios.get('/api/invoices').then(response => {
              this.invoices = response.data;
          });
      },
      assignSelectedInventoriesToInvoice() {
        if (!this.selectedInvoiceId || !this.selectedInventoryIds.length) {
          alert('Please select an invoice and at least one inventory item.');
          return;
        }

        this.selectedInventoryIds.forEach(inventoryId => {
          console.log('Assigning inventory with ID:', inventoryId); // 添加这行来检查 ID
          axios.post(`/api/inventories/${inventoryId}/assign/${this.selectedInvoiceId}`)
            .then(response => {
              console.log('Assignment successful:', response.data);
              this.fetchInventoriesData();
              this.selectedInvoiceId = null;
              this.selectedInventoryIds = [];
              this.currentStep = 1;
              this.fetchInvoices();
              Swal.fire({
                title: 'Success!',
                text: 'Inventory has been assigned to invoice successfully.',
                icon: 'success',
                showConfirmButton: false, // 不显示确认按钮
                timer: 2000 // 1秒后自动关闭
            });

            })
            .catch(error => {
              console.error('Error assigning inventory:', error.response.data);
            });
        });
      },
      getTypeText(typeValue) {
        const typeItem = this.typeitems.find(item => item.typevalue === typeValue);
        return typeItem ? typeItem.typetext : '';
        },
      formatDate(dateString) {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', options); // 注意这里使用 'en-GB' 以得到 DD-MM-YYYY 格式
      },
  
      formatTime(dateString) {
        const options = { hour: '2-digit', minute: '2-digit', hour12: true };
        const date = new Date(dateString);
        return date.toLocaleTimeString('en-US', options);
      },
      showFormMethod() {
        // this.resetForm();
        // this.showForm = true; // 这将隐藏表单
        this.currentStep = 1;
      },
      closeForm() {
        this.showForm = false; // 这将隐藏表单
        this.isEditMode = false; // 这将隐藏表单
        this.resetForm();
      },
      fetchInventoriesData() {
        axios.get('/api/inventories')
            .then(response => {
                // 过滤状态为 1 的库存项
                this.inventories = response.data
                .filter(item => String(item.status) === '1')  // 先过滤状态为 '1' 的项
                .sort((a, b) => {
                  // 按 no 升序排序
                  if (a.no < b.no) return -1;
                  if (a.no > b.no) return 1;

                  // 如果 no 相同，则按 date 降序排序
                  // 假设 date 是 'YYYY-MM-DD' 格式
                  return new Date(b.date) - new Date(a.date);
                });
                this.isLoading = false;
            })
            .catch(error => {
                console.error(error);
            });
    },
    updateInventoryDetail(index, newDetail, price) {
      this.$set(this.inventory.inventories, index, { detail: newDetail, price: price });
      this.updateTotalPrice(); // 更新总价
    },
    editItem(inventory) {
      this.resetForm();
      this.inventory = Object.assign({}, inventory);
    //   this.inventory.type = inventory.type;
      this.isEditMode = true; // 进入编辑模式
      this.showForm = true; // 显示表单
      this.inventory.type = inventory.type;
    },
    submitNewInventory() {
      const postData = {
        name: this.inventory.name,
        price: this.inventory.price,
        quantity: this.inventory.quantity,
        type: this.inventory.type
      };
      return axios.post('/api/inventories', postData)
        .then(response => {
          const inventory = response.data;
          this.fetchInventoriesData();
          this.closeForm();
          Swal.fire({
                title: 'Success!',
                text: 'Inventory has been created successfully.',
                icon: 'success',
                showConfirmButton: false, // 不显示确认按钮
                timer: 2000 // 1秒后自动关闭
            });
          console.log(response.data);
          return response; // Ensure a response is returned so you can use it later
        })
        .catch(error => {
          console.error(error);
          throw error; // Re-throw the error to be caught by the caller
        });
      },
      submitNewInventoryAndStay() {
        const postData = {
          name: this.inventory.name,
          price: this.inventory.price,
          quantity: this.inventory.quantity,
          type: this.inventory.type
        };
        return axios.post('/api/inventories', postData)
          .then(response => {
            const inventory = response.data;
            this.resetForm();
            Swal.fire({
                title: 'Success!',
                text: 'Inventory has been created successfully.',
                icon: 'success',
                showConfirmButton: false, // 不显示确认按钮
                timer: 2000 // 1秒后自动关闭
            });
            console.log(response.data);
            this.fetchInventoriesData();
            return response; // Ensure a response is returned so you can use it later
          })
          .catch(error => {
            console.error(error);
            throw error; // Re-throw the error to be caught by the caller
          });
        },
      submitEditInventory() {
        const updatedData = {
            name: this.inventory.name,
            price: this.inventory.price,
            quantity: this.inventory.quantity,
            type: this.inventory.type
        };
        axios.put(`/api/inventories/${this.inventory.id}`, updatedData)
          .then(response => {
            console.log(this.inventory.inventories); 
            this.fetchInventoriesData(); // 重新获取最新的维修记录列表
            this.showForm = false; // 隐藏表单
            this.isEditMode = false; // 退出编辑模式
            Swal.fire({
                  title: 'Success!',
                  text: 'Inventory has been updated successfully.',
                  icon: 'success',
                  showConfirmButton: false, // 不显示确认按钮
                  timer: 2000 // 1秒后自动关闭
              });
          })
          .catch(error => {
            console.error('Error updating inventory:', error);
          });
      },
        deleteItem(inventory) {
          if (window.confirm(`Are you sure you want to delete the inventory?`)) {
            axios.delete(`/api/inventories/${inventory.id}`)
              .then(() => {
                this.inventories = this.inventories.filter((data) => data.id !== inventory.id);
                Swal.fire({
                    title: 'Deleted!',
                    text: 'Inventory has been deleted successfully.',
                    icon: 'success',
                    showConfirmButton: false, // 不显示确认按钮
                    timer: 2000 // 1秒后自动关闭
                });
              })
              .catch(error => {
                console.error(error);
              });
          } else {
            console.log('Delete action cancelled by the user.');
          }
        },
        resetForm() {
          this.inventory = {
            name: '',
            price: '',
            quantity: ''
          };
        },
      }
    };
    </script>
    <style scoped>
    .v-list {
      max-height: 300px;
      overflow-y: auto;
    }
    .inventory-item {
      word-wrap: break-word; /* 在长单词或URL内部进行换行 */
    }
    .fade-enter-active, .fade-leave-active {
      transition: opacity 0.5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
      opacity: 0;
    }
    </style>