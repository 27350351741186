<template>
    <v-container fluid>
      <v-overlay :value="isLoading">
        <v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
      </v-overlay>
      <v-row>
        <v-col><h1>Purchase</h1></v-col>
        <v-col style="text-align: right;"><v-btn v-if="!showForm" color="primary" @click="showFormMethod">Add Purchase</v-btn></v-col>
      </v-row>
      <v-text-field v-if="!showForm"
        v-model="search"
        label="Search by Name"
        single-line
        clearable
      ></v-text-field>
      <v-data-table v-if="!showForm"
          :headers="headers"
          :items="filteredPurchases"
          class="elevation-1"
        >
        <template v-slot:item.date="{ item }">
          <span>{{ formatDate(item.date) }}</span>
        </template>
        <template v-slot:item.index="{ index }">
            <span>{{ index + 1 }}</span> <!-- 显示序号，加1是因为索引从0开始 -->
        </template>
          <template v-slot:item.actions="{ item }">
            <!-- <v-btn small color="primary" @click="editItem(item)">Edit</v-btn> -->
            <v-btn small color="error" @click="deleteItem(item)">Delete</v-btn>
          </template>
        </v-data-table>
      <!-- <v-simple-table v-if="!showForm">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Item Name</th>
              <th class="text-left">Item Price</th>
              <th class="text-left">Item Type</th>
              <th class="text-left">Quantity</th>
              <th class="text-left">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="purchase in sortedPurchase" :key="purchase.id">
              <td>{{ purchase.name }}</td>
              <td>{{ purchase.price }}</td>
              <td>{{ purchase.quantity }}</td>
              <td>{{ purchase.type }}</td>
              <td>
                <v-btn small color="primary" @click="editItem(purchase)">Edit</v-btn>
                <v-btn small color="error" @click="deleteItem(purchase)">Delete</v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table> -->
  
      <!-- <v-card v-if="showForm && !isEditMode">
        <v-card-title class="headline">Add Purchase</v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col cols="3">
                <v-text-field v-model="purchase.name" label="Item Name" outlined dense></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field v-model="purchase.unit_price" label="Item Price (RM)" outlined dense></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field v-model="totalPrice" label="Total Price (RM)" outlined dense :disabled="true"></v-text-field>
              </v-col>
            </v-row>
           
            <v-row>
              <v-col cols="3">
                <v-select
                  v-model="purchase.quantity"
                  :items="quantityOptions"
                  label="Quantity"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  v-model="purchase.type"
                  :items="typeItems"
                  item-text="text"
                  item-value="value"
                  label="Item Type"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-text-field
                    v-model="purchase.date"
                    label="Select Date"
                    type="date"
                    outlined
                    dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
              <v-autocomplete
                v-model="purchase.supplier_id"
                :items="suppliers"
                item-text="text"
                item-value="value"
                label="Supplier"
                outlined
                dense
                :search-input.sync="search"
              ></v-autocomplete>
              </v-col>
              <v-col cols="9">
                <v-text-field v-model="purchase.description" label="Description" outlined dense></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-btn color="primary" @click="closeForm">Cansel</v-btn>
        <v-btn color="success" @click="submitNewPurchase">New Purchase</v-btn>
        <v-btn color="success" @click="submitNewPurchaseAndStay">New Purchase & Stay</v-btn>
      </v-card> -->

      <v-card v-if="showForm && !isEditMode">
        <v-card-title>Add Purchases</v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col cols="12" sm="4">
                <v-autocomplete
                  v-model="purchaseInfo.supplier_id"
                  :items="suppliers"
                  item-text="text"
                  item-value="value"
                  label="supplier"
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4">
                <!-- <v-text-field
                  v-model="purchaseInfo.date"
                  label="Date"
                  type="date"
                  outlined
                  dense
                ></v-text-field> -->
                <v-dialog
                    v-model="dialog"
                    persistent
                    width="290px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="displayDate"
                            label="Date"
                            readonly
                            outlined
                            dense
                            v-bind="attrs"
                            v-on="on"
                            append-icon="mdi-calendar"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="purchaseInfo.date"
                        no-title
                        @input="dialog = false"
                    ></v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="purchaseInfo.description"
                  label="Inv No"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Item Name</th>
                    <th class="text-left">Unit Price</th>
                    <th class="text-left">Quantity</th>
                    <th class="text-left">Total Price</th>
                    <th class="text-left">Type</th>
                    <th class="text-left">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in tableItems" :key="index">
                    <td>{{ item.name }}</td>
                    <td>{{ item.unit_price }}</td>
                    <td>{{ item.quantity }}</td>
                    <td>{{ item.total_price }}</td>
                    <td>{{ getTypeText(item.type) }}</td>
                    <td>
                      <v-btn icon @click="removeItem(index)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td><v-text-field v-model="newItem.name" label="Name" dense></v-text-field></td>
                    <td><v-text-field v-model="newItem.unit_price" label="Unit Price" dense></v-text-field></td>
                    <td><v-text-field v-model="newItem.quantity" label="Quantity" dense></v-text-field></td>
                    <td><v-text-field v-model="newItem.total_price" label="Total Price" dense disabled></v-text-field></td>
                    <td>
                      <v-select
                        v-model="newItem.type"
                        :items="typeItems"
                        item-text="text"
                        item-value="value"
                        label="Item Type"
                        outlined
                        dense
                      ></v-select>
                    </td>
                    <td><v-btn color="primary" @click="addItem">Add</v-btn></td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-btn color="success" @click="submitPurchases">Submit All Purchases</v-btn>
            <v-btn color="red" @click="cancelPurchases">Cancel</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
  
      <v-card v-if="showForm && isEditMode">
        <v-card-title class="headline">Edit Purchase</v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col cols="9">
                <v-text-field v-model="purchase.name" label="Item Name" outlined dense></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field v-model="purchase.price" label="Item Price" outlined dense></v-text-field>
              </v-col>
            </v-row>
           
            <v-row>
              <v-col cols="3">
                <!-- <v-text-field v-model="purchase.quantity" label="Quantity" outlined dense></v-text-field> -->
                <v-select
                  v-model="purchase.quantity"
                  :items="quantityOptions"
                  label="Quantity"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  v-model="purchase.type"
                  :items="items"
                  item-text="text"
                  item-value="value"
                  label="Item Type"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="3">
                <flat-pickr
                  v-model="purchase.date"
                  :config="datePickerConfig"
                  class="form-control"
                  placeholder="Select Date (Y-m-d)"
                ></flat-pickr>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
              <v-autocomplete
                v-model="purchase.supplier_id"
                :items="suppliers"
                item-text="text"
                item-value="value"
                label="Supplier"
                outlined
                dense
                :search-input.sync="search"
              ></v-autocomplete>
              </v-col>
              <v-col cols="9">
                <v-text-field v-model="purchase.description" label="Description" outlined dense></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-btn color="primary" @click="submitEditPurchase">Edit Purchase</v-btn>
        <v-btn color="primary" @click="closeForm">Cansel</v-btn>
      </v-card>
    </v-container>
  </template>
    
  <script>
  import Swal from 'sweetalert2'; 
  import { countBy } from 'lodash';
  import flatPickr from 'vue-flatpickr-component';
  import 'flatpickr/dist/flatpickr.css'; // 引入样式
  import moment from 'moment';
  export default {
    components: {
    flatPickr
  },
    data() {
      return {
        dialog: false,
        search: '',
        quantityOptions: Array.from({ length: 15 }, (_, i) => i + 1),
        headers: [
            { text: 'No', value: 'index', sortable: false },
            { text: 'Name', value: 'name' },
            { text: 'Quantity', value: 'quantity' },
            { text: 'Price (RM)', value: 'unit_price' },
            { text: 'Total Price (RM)', value: 'total_price' },
            { text: 'Type', value: 'typeText' },
            { text: 'Date', value: 'date' },
            { text: 'Supplier', value: 'supplierName' },
            { text: 'Description', value: 'description' },
            { text: 'Actions', value: 'actions', sortable: false },
        ],
        // typeitems: [
        //     { typetext: 'Part', typevalue: 1 },
        //     { typetext: 'Accessories', typevalue: 2 },
        // ],
        isEditMode: false, // 默认为添加模式
        showForm: false, // 控制表单显示的变量
        suppliers: [],
        purchases: [],
        items: [],
        // multiplePurchases: [],  // 用于存储多个采购项
        // newMultiplePurchase: {
        //   name: '',
        //   unit_price: '',
        //   quantity: '',
        //   type: '',
        //   date: '',
        //   description: '',
        //   supplier_id: '',
        // },
        purchase: {
            name: '',
            price: '',
            quantity: 1,
            type: '',
            date: '',
            supplier_id: '',
            description: '',
        },
        datePickerConfig: {
          dateFormat: 'Y-m-d',
          allowInput: true
        },
        typeItems: [
          { text: 'Part', value: '1' },
          { text: 'Accessories', value: '2' },
          { text: 'Delivery', value: '3' },
          { text: 'Expense', value: '4' },
        ],
        isLoading: true,
        purchaseInfo: {
          supplier_id: '',
          description: '',
          date: '',
        },
        // 单个采购项的信息
        newItem: {
          name: '',
          unit_price: '',
          quantity: '1',
          total_price: '',
          type: '1',
        },
        // // 存储所有采购项的数组
        tableItems: [],
        lastAddedItem: null,
      };
    },
    created() {
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    },
    mounted() {
      this.fetchSuppliers();
      this.fetchPurchasesData();
      console.log(this.fetchPurchasesData);
      console.log(this.purchases);
    },
    computed: {
      displayDate: {
          get() {
              if (!this.purchaseInfo.date) return '';
              const [year, month, day] = this.purchaseInfo.date.split('-');
              return `${day}/${month}/${year}`;
          },
          set(value) {
              const [day, month, year] = value.split('/');
              this.purchaseInfo.date = `${year}-${month}-${day}`;
          }
      },
      filteredPurchases() {
        return this.purchases
          .filter(purchase => {
            const searchLower = this.search.toLowerCase();
            return purchase.name.toLowerCase().includes(searchLower) ||
                  purchase.description.toLowerCase().includes(searchLower);
          })
          .sort((a, b) => new Date(b.date) - new Date(a.date)); // 添加这行代码进行排序
      },
      calculateTotalPrice() {
        return Number(this.newItem.unit_price) * Number(this.newItem.quantity);
      },
      newItemTotalPrice() {
       return Number(this.newItem.unit_price) * Number(this.newItem.quantity);
      },
      totalItemsPrice() {
        return this.tableItems.reduce((acc, item) => acc + Number(item.unit_price) * Number(item.quantity), 0);
      },
      combinedTotalPrice() {
        // 计算 tableItems 的总价
        const itemsTotal = this.tableItems.reduce((acc, item) => acc + Number(item.unit_price) * Number(item.quantity), 0);
        // 加上当前 newItem 的总价
        const newItemTotal = Number(this.newItem.unit_price) * Number(this.newItem.quantity);
        return itemsTotal + newItemTotal;
      },
      totalPrice() {
        return this.purchase.unit_price * this.purchase.quantity;
      },
      // enrichedPurchases() {
      //   return this.purchases.map(purchase => ({
      //     ...purchase,
      //     supplierName: this.suppliers.find(s => s.value === purchase.supplier_id)?.text || 'Unknown',
      //     typeText: this.typeItems.find(typeItem => typeItem.value === purchase.type)?.text || 'Unknown'
      //   }));
      // },
      // purchasesWithTypeName() {
      //   return this.purchases.map(purchase => ({
      //     ...purchase,
      //     typeText: this.typeItems.find(typeItem => typeItem.value === purchase.type)?.text || 'Unknown'
      //   }));
      // },
      // typeName() {
      //   const type = this.typeItems.find(item => item.value === this.purchase.type);
      //   return type ? type.text : '';
      // },
      sortedPurchase() {
        // const filteredPurchases = this.purchases.filter(purchase => Number(purchase.status) === 1);
        // console.log(filteredPurchases); // 查看过滤后的结果
  
        // const sorted = filteredPurchases.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
        const sorted = this.purchases.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
        console.log(sorted); // 查看排序后的结果
        return sorted;
      },
      // itemsWithItemTypeText() {
      //   return this.purchases.map(purchase => {
      //       return {
      //       ...purchase,
      //       type: this.getTypeText(purchase.type),
      //       };
      //   });
      //   },
    },
    watch: {
      calculateTotalPrice(newVal) {
        this.newItem.total_price = newVal;
      }
    },
    methods: {
      // formatDate(date) {
      //   return moment(date).format('DD MM YY');
      // },
      getTypeText(typeValue) {
        const typeItem = this.typeItems.find(item => item.value === typeValue);
        return typeItem ? typeItem.text : 'Unknown Type';
      },
      cancelPurchases() {
        this.tableItems = [];
        this.purchaseInfo = { supplier_id: '', description: '', date: '' };
        this.newItem = { name: '', unit_price: '', quantity: '1', total_price: '', type: '1' }; // 重置 newItem
        this.isEditMode = false;
        this.showForm = false;
      },
      addItem() {
        // 计算当前 newItem 的总价并设置
        this.newItem.total_price = Number(this.newItem.unit_price) * Number(this.newItem.quantity);
        
        // 将 newItem 添加到 tableItems
        this.tableItems.push({...this.newItem});
        
        // 重置 newItem 为下一个条目的输入
        this.newItem = { name: '', unit_price: '', quantity: '1', type: '1', total_price: '' };
      },
      removeItem(index) {
        this.tableItems.splice(index, 1);
      },
      submitPurchases() {
        console.log('Submitting purchases', this.tableItems);
        const promises = this.tableItems.map(item => {
          const postData = {
            ...this.purchaseInfo,
            name: item.name,
            quantity: item.quantity,
            unit_price: item.unit_price,
            total_price: item.total_price,
            type: item.type,
            date: item.date || this.purchaseInfo.date,
            supplier_id: item.supplier_id || this.purchaseInfo.supplier_id,
            description: item.description || this.purchaseInfo.description
          };
          console.log('Posting data', postData);
          return axios.post('/api/purchases', postData);
        });

        return Promise.all(promises)
          .then(responses => {
            console.log('All requests successful', responses);
            this.tableItems = [];
            this.purchaseInfo = { supplier_id: '', description: '', date: '' };
            this.newItem = { name: '', unit_price: '', quantity: '1', total_price: '', type: '1' }; // 重置 newItem
            this.isEditMode = false;
            this.fetchPurchasesData();
            Swal.fire({
              title: 'Success!',
              text: 'All purchases have been successfully saved!',
              icon: 'success',
              showConfirmButton: false,
              timer: 2000
            });
          })
          .catch(error => {
            console.error('Error posting purchase data:', error);
          });
      },
      fetchSuppliers() {
        axios.get('/api/suppliers')
          .then(response => {
            this.suppliers = response.data.map(supplier => ({
              text: supplier.company_name,
              value: supplier.id.toString()  // Ensure the value is a string
            }));
          })
          .catch(error => {
            console.error('Error fetching suppliers:', error);
          });
      },
        // getTypeText(typeValue) {
        // const typeItem = this.typeitems.find(item => item.typevalue === typeValue);
        // return typeItem ? typeItem.typetext : '';
        // },
      formatDate(dateString) {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', options); // 注意这里使用 'en-GB' 以得到 DD-MM-YYYY 格式
      },
  
      formatTime(dateString) {
        const options = { hour: '2-digit', minute: '2-digit', hour12: true };
        const date = new Date(dateString);
        return date.toLocaleTimeString('en-US', options);
      },
      showFormMethod() {
        this.resetForm();
        this.showForm = true; // 这将隐藏表单
      },
      closeForm() {
        this.showForm = false; // 这将隐藏表单
        this.isEditMode = false; // 这将隐藏表单
        this.resetForm();
      },
      fetchPurchasesData() {
        axios.get('/api/purchases')
          .then(response => {
            this.purchases = response.data.map(purchase => {
              const supplierName = this.suppliers.find(s => s.value === purchase.supplier_id.toString())?.text || 'Unknown';
              const typeText = this.typeItems.find(typeItem => typeItem.value === purchase.type.toString())?.text || 'Unknown';

              return {
                ...purchase,
                supplierName: supplierName,
                typeText: typeText
              };
            });
            this.isLoading = false;
          })
          .catch(error => {
            console.error('Error fetching purchases:', error);
          });
      },
    updatePurchaseDetail(index, newDetail, price) {
      this.$set(this.purchase.purchases, index, { detail: newDetail, price: price });
      this.updateTotalPrice(); // 更新总价
    },
    editItem(purchase) {
      this.resetForm();
      this.purchase = Object.assign({}, purchase);
      this.isEditMode = true; // 进入编辑模式
      this.showForm = true; // 显示表单
    },
    submitNewPurchase() {
      const postData = {
        name: this.purchase.name,
        // price: this.purchase.price,
        quantity: this.purchase.quantity,
        unit_price: this.purchase.unit_price,
        total_price: this.totalPrice,
        type: this.purchase.type,
        date: this.purchase.date,
        supplier_id: this.purchase.supplier_id,
        description: this.purchase.description
      };
      return axios.post('/api/purchases', postData)
        .then(response => {
          const purchase = response.data;
          this.fetchPurchasesData();
          this.closeForm();
          Swal.fire({
                title: 'Success!',
                text: 'Purchase has been created successfully.',
                icon: 'success',
                showConfirmButton: false, // 不显示确认按钮
                timer: 2000 // 1秒后自动关闭
            });
          console.log(response.data);
          return response; // Ensure a response is returned so you can use it later
        })
        .catch(error => {
          console.error(error);
          throw error; // Re-throw the error to be caught by the caller
        });
      },
      submitNewPurchaseAndStay() {
        const postData = {
        name: this.purchase.name,
        // price: this.purchase.price,
        quantity: this.purchase.quantity,
        unit_price: this.purchase.unit_price,
        total_price: this.totalPrice,
        type: this.purchase.type,
        date: this.purchase.date,
        supplier_id: this.purchase.supplier_id,
        description: this.purchase.description
      };
      return axios.post('/api/purchases', postData)
        .then(response => {
          const purchase = response.data;
          this.fetchPurchasesData();
          this.resetForm();
          Swal.fire({
                title: 'Success!',
                text: 'Purchase has been created successfully.',
                icon: 'success',
                showConfirmButton: false, // 不显示确认按钮
                timer: 2000 // 1秒后自动关闭
            });
          console.log(response.data);
          return response; // Ensure a response is returned so you can use it later
        })
        .catch(error => {
          console.error(error);
          throw error; // Re-throw the error to be caught by the caller
        });
        },
      submitEditPurchase() {
        const updatedData = {
            name: this.purchase.name,
            price: this.purchase.price,
            quantity: this.purchase.quantity,
            type: this.purchase.type,
            date: this.purchase.date,
            supplier_id: this.purchase.supplier_id,
            description: this.purchase.description
        };
        axios.put(`/api/purchases/${this.purchase.id}`, updatedData)
          .then(response => {
            console.log(this.purchase.purchases); 
            this.fetchPurchasesData(); // 重新获取最新的维修记录列表
            this.showForm = false; // 隐藏表单
            this.isEditMode = false; // 退出编辑模式
            Swal.fire({
                  title: 'Success!',
                  text: 'Purchase has been updated successfully.',
                  icon: 'success',
                  showConfirmButton: false, // 不显示确认按钮
                  timer: 2000 // 1秒后自动关闭
              });
          })
          .catch(error => {
            console.error('Error updating purchase:', error);
          });
      },
        deleteItem(purchase) {
          if (window.confirm(`Are you sure you want to delete the purchase?`)) {
            axios.delete(`/api/purchases/${purchase.id}`)
              .then(() => {
                this.purchases = this.purchases.filter((data) => data.id !== purchase.id);
                Swal.fire({
                    title: 'Deleted!',
                    text: 'Purchase has been deleted successfully.',
                    icon: 'success',
                    showConfirmButton: false, // 不显示确认按钮
                    timer: 2000 // 1秒后自动关闭
                });
              })
              .catch(error => {
                console.error(error);
              });
          } else {
            console.log('Delete action cancelled by the user.');
          }
        },
        resetForm() {
          this.purchase = {
            name: '',
            price: '',
            quantity: '',
            type: '',
            description: '',
            date: '',
            supplier_id: '',
          };
        },
      }
    };
    </script>
    <style scoped>
    .purchase-item {
      word-wrap: break-word; /* 在长单词或URL内部进行换行 */
    }
    </style>