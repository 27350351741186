<template>
    <v-container fluid>
      <v-overlay :value="isLoading">
        <v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
      </v-overlay>
      <v-row>
        <v-col><h1>Supplier</h1></v-col>
        <v-col style="text-align: right;"><v-btn v-if="!showForm" color="primary" @click="showFormMethod">Add Supplier</v-btn></v-col>
      </v-row>
      <v-data-table v-if="!showForm"
        :headers="headers"
        :items="suppliers"
        class="elevation-1"
    >
    <template v-slot:item="{ item, index }">
        <tr>
        <td>{{ index + 1 }}</td>
        <td>{{ item.company_name }}</td>
        <td>{{ item.contact_name }}</td>
        <td>{{ item.contact_phone }}</td>
        <td>{{ item.address }}</td>
        <td>
            <v-btn small color="primary" @click="editItem(item)">Edit</v-btn>
            <v-btn small color="error" @click="deleteItem(item)">Delete</v-btn>
        </td>
        </tr>
    </template>
    </v-data-table>
      <v-card v-if="showForm && !isEditMode">
        <v-card-title class="headline">Add Supplier</v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-row>
                <v-text-field v-model="supplier.company_name" label="Company Name" outlined dense></v-text-field>
            </v-row>
            <v-row>
                <v-text-field v-model="supplier.contact_name" label="Contact Name" outlined dense></v-text-field>
            </v-row>
            <v-row>
                <v-text-field v-model="supplier.contact_phone" label="Contact Phone" outlined dense></v-text-field>
            </v-row>
            <v-row>
                <v-text-field v-model="supplier.address" label="Company Address" outlined dense></v-text-field>
            </v-row>
          </v-form>
        </v-card-text>
        <v-btn color="primary" @click="closeForm">Cansel</v-btn>
        <v-btn color="success" @click="submitNewSupplier">New Supplier</v-btn>
        <v-btn color="success" @click="submitNewSupplierAndStay">New Supplier & Stay</v-btn>
      </v-card>
  
      <v-card v-if="showForm && isEditMode">
        <v-card-title class="headline">Edit Supplier</v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-row>
                <v-text-field v-model="supplier.company_name" label="Company Name" outlined dense></v-text-field>
            </v-row>
            <v-row>
                <v-text-field v-model="supplier.contact_name" label="Contact Name" outlined dense></v-text-field>
            </v-row>
            <v-row>
                <v-text-field v-model="supplier.contact_phone" label="Contact Phone" outlined dense></v-text-field>
            </v-row>
            <v-row>
                <v-text-field v-model="supplier.address" label="Company Address" outlined dense></v-text-field>
            </v-row>
          </v-form>
        </v-card-text>
        <v-btn color="primary" @click="submitEditSupplier">Edit Supplier</v-btn>
        <v-btn color="primary" @click="closeForm">Cansel</v-btn>
      </v-card>
    </v-container>
  </template>
    
  <script>
  import Swal from 'sweetalert2'; 
  import { countBy } from 'lodash';
  export default {
    data() {
      return {
        headers: [
            { text: 'ID', value: 'autoIndex', sortable: false },
            { text: 'Company Name', value: 'company_name' },
            { text: 'Contact Name', value: 'contact_name' },
            { text: 'Contact Phone', value: 'contact_phone' },
            { text: 'Company Address', value: 'address' },
            { text: 'Actions', value: 'actions', sortable: false },
        ],
        items: [
        ],
        isEditMode: false, // 默认为添加模式
        showForm: false, // 控制表单显示的变量
        suppliers: [],
        supplier: {
            company_name: '',
            contact_name: '',
            contact_phone: '',
            address: ''
        },
        isLoading: true,
      };
    },
    created() {
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    },
    mounted() {
      this.fetchSuppliersData();
    },
    computed: {
      sortedSupplier() {
        const sorted = this.suppliers.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
        console.log(sorted); // 查看排序后的结果
        return sorted;
      },
    },
    methods: {
      formatDate(dateString) {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', options); // 注意这里使用 'en-GB' 以得到 DD-MM-YYYY 格式
      },
  
      formatTime(dateString) {
        const options = { hour: '2-digit', minute: '2-digit', hour12: true };
        const date = new Date(dateString);
        return date.toLocaleTimeString('en-US', options);
      },
      showFormMethod() {
        this.resetForm();
        this.showForm = true; // 这将隐藏表单
      },
      closeForm() {
        this.showForm = false; // 这将隐藏表单
        this.isEditMode = false; // 这将隐藏表单
        this.resetForm();
      },
      fetchSuppliersData() {
      axios.get('/api/suppliers')
        .then(response => {
          this.suppliers = response.data;
          this.isLoading = false;
        })
        .catch(error => {
          console.error(error);
        });
    },
    updateSupplierDetail(index, newDetail, price) {
      this.$set(this.supplier.suppliers, index, { detail: newDetail, price: price });
      this.updateTotalPrice(); // 更新总价
    },
    editItem(supplier) {
      this.resetForm();
      this.supplier = Object.assign({}, supplier);
      this.isEditMode = true; // 进入编辑模式
      this.showForm = true; // 示表单
    },
    submitNewSupplier() {
      const postData = {
        company_name: this.supplier.company_name,
        contact_name: this.supplier.contact_name,
        contact_phone: this.supplier.contact_phone,
        address: this.supplier.address
      };
      return axios.post('/api/suppliers', postData)
        .then(response => {
          const supplier = response.data;
          this.fetchSuppliersData();
          this.closeForm();
          Swal.fire({
                title: 'Success!',
                text: 'Supplier has been created successfully.',
                icon: 'success',
                showConfirmButton: false, // 不显示确认按钮
                timer: 2000 // 1秒后自动关闭
            });
          console.log(response.data);
          return response; // Ensure a response is returned so you can use it later
        })
        .catch(error => {
          console.error(error);
          throw error; // Re-throw the error to be caught by the caller
        });
      },
      submitNewSupplierAndStay() {
        const postData = {
            company_name: this.supplier.company_name,
            contact_name: this.supplier.contact_name,
            contact_phone: this.supplier.contact_phone,
            address: this.supplier.address
        };
        return axios.post('/api/suppliers', postData)
          .then(response => {
            const supplier = response.data;
            this.resetForm();
            Swal.fire({
                title: 'Success!',
                text: 'Supplier has been created successfully.',
                icon: 'success',
                showConfirmButton: false, // 不显示确认按钮
                timer: 2000 // 1秒后自动关闭
            });
            console.log(response.data);
            this.fetchSuppliersData();
            return response; // Ensure a response is returned so you can use it later
          })
          .catch(error => {
            console.error(error);
            throw error; // Re-throw the error to be caught by the caller
          });
        },
      submitEditSupplier() {
        const updatedData = {
            company_name: this.supplier.company_name,
            contact_name: this.supplier.contact_name,
            contact_phone: this.supplier.contact_phone,
            address: this.supplier.address
        };
        axios.put(`/api/suppliers/${this.supplier.id}`, updatedData)
          .then(response => {
            console.log(this.supplier.suppliers); 
            this.fetchSuppliersData(); // 重新获取最新的维修记录列表
            this.showForm = false; // 隐藏表单
            this.isEditMode = false; // 退出编辑模式
            Swal.fire({
                  title: 'Success!',
                  text: 'Supplier has been updated successfully.',
                  icon: 'success',
                  showConfirmButton: false, // 不显示确认按钮
                  timer: 2000 // 1秒后自动关闭
              });
          })
          .catch(error => {
            console.error('Error updating supplier:', error);
          });
      },
        deleteItem(supplier) {
          if (window.confirm(`Are you sure you want to delete the supplier?`)) {
            axios.delete(`/api/suppliers/${supplier.id}`)
              .then(() => {
                this.suppliers = this.suppliers.filter((data) => data.id !== supplier.id);
                Swal.fire({
                    title: 'Deleted!',
                    text: 'Supplier has been deleted successfully.',
                    icon: 'success',
                    showConfirmButton: false, // 不显示确认按钮
                    timer: 2000 // 1秒后自动关闭
                });
              })
              .catch(error => {
                console.error(error);
              });
          } else {
            console.log('Delete action cancelled by the user.');
          }
        },
        resetForm() {
          this.supplier = {
            company_name: '',
            contact_name: '',
            contact_phone: '',
            address: ''
          };
        },
      }
    };
    </script>
    <style scoped>
    .supplier-item {
      word-wrap: break-word; /* 在长单词或URL内部进行换行 */
    }
    </style>