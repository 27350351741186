<template>
  <v-app>
    <v-app-bar color="indigo" dark app>
      <v-btn icon class="hidden-md-and-up" @click="toggleMenu" v-if="isLoggedIn">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <v-toolbar-title>Future Link</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- 在大屏幕上显示按钮，在小屏幕上隐藏 -->
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn text to="/quotation" v-if="isLoggedIn">Quotation</v-btn>
        <v-btn text to="/repair" v-if="isLoggedIn">Repair</v-btn>
        <v-btn text to="/Bill" v-if="isLoggedIn">Completed</v-btn>
        <v-btn text to="/invoice" v-if="isLoggedIn">Sale</v-btn>
        <v-menu offset-y v-if="isLoggedIn">
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on">Account</v-btn>
          </template>
          <v-list>
            <v-list-item to="/account">
              <v-list-item-title>Summary</v-list-item-title>
            </v-list-item>
            <v-list-item to="/inventory">
              <v-list-item-title>Inventory</v-list-item-title>
            </v-list-item>
            <v-list-item to="/purchase">
              <v-list-item-title>Purchase</v-list-item-title>
            </v-list-item>
            <v-list-item to="/payment">
              <v-list-item-title>Payment</v-list-item-title>
            </v-list-item>
            <v-list-item to="/suppliers">
              <v-list-item-title>Suppliers</v-list-item-title>
            </v-list-item>
            <v-list-item to="/shopOpenTimes">
              <v-list-item-title>Shop Open Times</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
      <!-- 在小屏幕上显示菜单按钮，在大屏幕上隐藏 -->
     
      <v-btn icon v-if="isLoggedIn" @click.prevent="logout">
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app temporary>
      <v-list dense>
        <v-list-item to="/quotation" v-if="isLoggedIn">
          <v-list-item-title>Quotation</v-list-item-title>
        </v-list-item>
        <v-list-item to="/repair" v-if="isLoggedIn">
          <v-list-item-title>Repair</v-list-item-title>
        </v-list-item>
        <v-list-item to="/Bill" v-if="isLoggedIn">
          <v-list-item-title>Completed</v-list-item-title>
        </v-list-item>
        <v-list-item to="/invoice" v-if="isLoggedIn">
          <v-list-item-title>Sale</v-list-item-title>
        </v-list-item>
        <v-list-item to="/account">
          <v-list-item-title>Summary</v-list-item-title>
        </v-list-item>
        <v-list-item to="/inventory">
          <v-list-item-title>Inventory</v-list-item-title>
        </v-list-item>
        <v-list-item to="/purchase">
          <v-list-item-title>Purchase</v-list-item-title>
        </v-list-item>
        <v-list-item to="/payment">
          <v-list-item-title>Payment</v-list-item-title>
        </v-list-item>
        <v-list-item to="/suppliers">
          <v-list-item-title>Suppliers</v-list-item-title>
        </v-list-item>
        <v-list-item to="/shopOpenTimes">
          <v-list-item-title>Shop Open Times</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>


<script>
import axios from 'axios';
import Swal from 'sweetalert2';
export default {
  data() {
    return {
      drawer: false,
      isLoggedIn: false,
    };
  },
  // created() {
  //   if (this.isLoggedIn) {
  //     axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
  //   }
  // },
  // created() {
  //   this.checkLoginStatus();
  // },
  created() {
    this.checkLoginStatus();
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
  },
  watch: {
    '$route': function() {
      this.isLoggedIn = !!localStorage.getItem('token');
    }
  },
  mounted() {
    // this.checkLoginStatus(); // 初始检查
    this.interval = setInterval(this.checkLoginStatus, 600000); // 每10分钟检查一次

    // 设置 Axios 请求拦截器
    axios.interceptors.request.use((config) => {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    }, (error) => {
      return Promise.reject(error);
    });

    this.reloadInterval = setInterval(() => {
      window.location.reload();
    }, 3600000); // 3600000 毫秒 = 1 小时
  },
  methods: {
    toggleMenu() {
      this.drawer = !this.drawer;
    },
    beforeDestroy() {
      clearInterval(this.interval);
    },
    checkLoginStatus() {
      console.log("Checking login status, current token:", localStorage.getItem('token'));
          const token = localStorage.getItem('token');
      if (token) {
        axios.get('/api/auth/validate', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }).then(response => {
          this.isLoggedIn = true;
        }).catch(error => {
          console.error("Error validating token:", error);
          console.error("Detailed error:", error.response ? error.response.data : "No response data");
          this.isLoggedIn = false;
          localStorage.removeItem('token');
          this.promptLogin();
        });
      } else {
        console.error("Error validating token:", error);
        this.isLoggedIn = false;
        localStorage.removeItem('token');
        this.promptLogin();
        this.$forceUpdate(); 
      }
    },
    promptLogin() {
      Swal.fire({
        title: 'Session Expired',
        text: 'Please log in again to continue.',
        icon: 'warning',
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.value) {
          this.$router.push('/');
        }
      });
    },
    logout() {
      // Send a request to the logout API
      axios.post('/api/auth/logout', {}, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
      .then(() => {
        // Remove the token from localStorage
        localStorage.removeItem('token');
        this.isLoggedIn = false;
        // Redirect to the login page
        Swal.fire({
          title: 'Success!',
          text: 'Logout successfully.',
          icon: 'success',
          showConfirmButton: false, // 不显示确认按钮
          timer: 2000 // 1秒后自动关闭
        });
      this.$router.push('/');
      })
      .catch(error => {
        console.log(error);
        this.isLoggedIn = false;
        localStorage.removeItem('token');
      });
    }
  }
}
</script>
<style>
.custom-toolbar {
  max-height: 70px;
}

@media (max-width: 960px) {
  .hidden-sm-and-down {
    display: none;
  }
}

@media (min-width: 961px) {
  .hidden-md-and-up {
    display: none;
  }
}
</style>