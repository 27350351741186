<template>
  <v-app style="background-color: gray; background-repeat: no-repeat; background-size: cover;">
    <v-main>
      <v-container fill-height fluid>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card>
              <v-card-title>Login</v-card-title>
              <v-card-text>
                <v-form @submit.prevent="login">
                  <v-text-field label="Email" type="email" v-model="email" required></v-text-field>
                  <v-text-field label="Password" type="password" v-model="password" required enter-key-hint></v-text-field>
                  <v-btn type="submit">Login</v-btn>
                  <p v-if="error">Email or password is incorrect</p>
                </v-form>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
  data() {
    return {
      email: '',
      password: '',
      error: false
    } 
  },
  methods: {
    login() {
      axios.post('/api/auth/login', {
        email: this.email,
        password: this.password,
        device_name: 'browser'
      })
      .then(response => {
        // Save the token
        localStorage.setItem('token', response.data.token);
        this.$router.push('/repair');
        
        Swal.fire({
          title: 'Success!',
          text: 'Login successfully.',
          icon: 'success',
          showConfirmButton: false, // 不显示确认按钮
          timer: 2000 // 1秒后自动关闭
        });
      })
      .catch(error => {
        this.error = true;
        console.log(error);
      });
    }
  }
}
</script>