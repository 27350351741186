<template>
    <v-container fluid>
        <v-overlay :value="isLoading">
        <v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
        </v-overlay>
        <v-card v-if="!showForm && !showEditForm">
            <v-row>
                <v-col>
                    <v-card-title>Shop Open Times</v-card-title>
                </v-col>
                <v-col>
                    <v-btn @click="addTime">Add Time</v-btn>
                </v-col>
            </v-row>
            <v-simple-table v-if="!showForm">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Open Time</th>
                        <th>Close Time</th>
                        <th>User</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="shopOpenTime in shopOpenTimes" :key="shopOpenTime.id">
                        <td>{{ formatDate(shopOpenTime.date) }}</td>
                        <td>{{ shopOpenTime.open_time }}</td>
                        <td>{{ shopOpenTime.close_time }}</td>
                        <td>{{ shopOpenTime.user.name }}</td>
                        <td>
                            <v-btn @click="editTime(shopOpenTime)">Edit</v-btn>
                            <v-btn @click="deleteTime(shopOpenTime.id)">Delete</v-btn>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-card>
        <v-card v-if="showForm">
            <v-row>
                <v-col>
                    <v-card-title>Add Shop Open Time</v-card-title>
                </v-col>
                <v-col>
                    <v-btn @click="showForm = false">Back</v-btn>
                </v-col>
            </v-row>
            <v-card-text>
                <v-form ref="form">
                    <v-row>
                        <v-col>
                            <v-dialog
                                v-model="dialog"
                                persistent
                                width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="displayDate"
                                        label="Date"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        append-icon="mdi-calendar"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="addingShopOpenTime.date"
                                    no-title
                                    @input="dialog = false"
                                ></v-date-picker>
                            </v-dialog>
                            <v-text-field v-model="addingShopOpenTime.date" type="date" label="Date"></v-text-field>
                            
                        </v-col>
                        <v-col>
                            <v-text-field v-model="addingShopOpenTime.open_time" type="time" label="Open Time"></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field v-model="addingShopOpenTime.close_time" type="time" label="Close Time"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-select v-model="addingShopOpenTime.user_id" :items="users" label="User" item-text="name" item-value="id"></v-select>
                    <v-btn @click="saveTime">Save</v-btn>
                    <v-btn @click="cancelForm">Cansel</v-btn>
                </v-form>
            </v-card-text>
        </v-card>
        <v-card v-if="showEditForm">
            <v-row>
                <v-col>
                    <v-card-title>Edit Shop Open Time</v-card-title>
                </v-col>
                <v-col>
                    <v-btn @click="showEditForm = false">Back</v-btn>
                </v-col>
            </v-row>
            <v-card-text>
                <v-form ref="editForm">
                    <v-row>
                        <v-col>
                            <v-text-field v-model="editingShopOpenTime.date" label="Date"></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field v-model="editingShopOpenTime.open_time" label="Open Time"></v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field v-model="editingShopOpenTime.close_time" label="Close Time"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-select v-model="editingShopOpenTime.user_id" :items="users" label="User" item-text="name" item-value="id"></v-select>
                    <v-btn @click="updateTime">Update</v-btn>
                </v-form>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import Swal from 'sweetalert2';
export default {
    data() {
        return {
            dialog: false,
            shopOpenTimes: [],
            editingShopOpenTime: {
                id: '',
                date: '',
                open_time: '',
                close_time: '',
                user_id: '',
            },
            addingShopOpenTime: {
                date: '',
                open_time: '',
                close_time: '',
                user_id: '',
            },
            selectedUser: {},
            showForm: false,
            showEditForm: false, // Add this line
            date: '',
            open_time: '',
            close_time: '',
            user_id: '',
            users: [],
            isLoading: true,
        }
    },
    mounted() {
        this.getUsers();
        this.getShopOpenTimes();
    },
    computed: {
        displayDate: {
            get() {
                if (!this.addingShopOpenTime.date) return '';
                const [year, month, day] = this.addingShopOpenTime.date.split('-');
                return `${day}/${month}/${year}`;
            },
            set(value) {
                const [day, month, year] = value.split('/');
                this.addingShopOpenTime.date = `${year}-${month}-${day}`;
            }
        }
    },
    methods: {
        cancelForm() {
            this.showForm = false;
            this.showEditForm = false;
            this.clearForm();
        },
        formatDate(dateString) {
            const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
            const date = new Date(dateString);
            return date.toLocaleDateString('en-GB', options); // 注意这里使用 'en-GB' 以得到 DD-MM-YYYY 格式
        },
        getUsers() {
            axios.get('/api/users')
            .then(response => {
                this.users = response.data;
            })
            .catch(error => {
                console.error(error);
            });
        },
        getShopOpenTimes() {
            const token = localStorage.getItem('token');
            axios.get('/api/shop-open-times')
            .then(response => {
                this.shopOpenTimes = response.data;
                this.isLoading = false;
            })
            .catch(error => {
                console.error(error);
            });
        },
        addTime() {
            this.showForm = true;
            this.clearForm();
        },
        editTime(shopOpenTime) {
            this.editingShopOpenTime = shopOpenTime;
            this.showEditForm = true;
        },
        saveTime() {
            axios.post('/api/shop-open-times', this.addingShopOpenTime)
            .then(response => {
                this.getShopOpenTimes();
                this.showForm = false;
                this.clearForm();
                Swal.fire({
                    icon: 'success',
                    title: 'Shop open time added successfully',
                    showConfirmButton: false,
                    timer: 1500
                });
            })
            .catch(error => {
                console.error(error);
            });
        },
        updateTime() {
            axios.put(`/api/shop-open-times/${this.editingShopOpenTime.id}`, this.editingShopOpenTime)
            .then(response => {
                this.getShopOpenTimes();
                this.showEditForm = false;
                this.clearForm();
                Swal.fire({
                    icon: 'success',
                    title: 'Shop open time updated successfully',
                    showConfirmButton: false,
                    timer: 1500
                });
            })
            .catch(error => {
                console.error(error);
            });
        },
        deleteTime(id) {
            Swal.fire({
                icon: 'warning',
                title: 'Are you sure you want to delete this shop open time?',
                showCancelButton: true,
                confirmButtonText: 'Delete'
            })
            .then(result => {
                if (result.dismiss) {
                    return;
                }
            })
            .then(response => {
                axios.delete(`/api/shop-open-times/${id}`)
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Shop open time deleted successfully',
                        showConfirmButton: false,
                        timer: 1500
                    });
                    this.getShopOpenTimes();
                })
                .catch(error => {
                    console.error(error);
                });
            })
        },
        clearForm() {
            this.addingShopOpenTime = {
                date: '',
                open_time: '',
                close_time: '',
                user_id: '',
            };
            this.editingShopOpenTime = {
                id: '',
                date: '',
                open_time: '',
                close_time: '',
                user_id: '',
            };
        }
    }
}
</script>