require('./bootstrap');

window.Vue = require('vue').default;
import router from './router'
import Vue from 'vue';
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import App from './App.vue'
import axios from 'axios'; // 导入 axios
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify)
Vue.use(axios)
const app = new Vue({
    el: '#app',
    router,
    vuetify: new Vuetify(), // 添加这一行
    components: { App },
    template: '<App />'
});